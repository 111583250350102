<template>
  <div>
    <BlockUI :blocked="blocked" :fullScreen="true">
      <Card class="main-content">
        <template #content>
          <DataTable :value="dataSource" responsiveLayout="scroll">
            <Column field="created_at" header="Date">
              <template #body="{ data }">
                {{ formatUSDate(data.created_at) }}
              </template>
            </Column>
            <Column field="note" header="Note">
              <template #body="{ data }">
                {{ data.note }}
              </template>
            </Column>
            <Column header="Action" columnKey="action">
              <template #body="{ data }">
                <div style="display: flex">
                  <div>
                    <Button
                      class="p-button-outlined p-button-secondary"
                      @click="runSavedQuery(data.query)"
                    >
                      Query
                    </Button>
                  </div>
                  <div style="margin-left: 0.5rem">
                    <Button @click="deleteSavedQuery(data.id)">Delete</Button>
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </BlockUI>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Card from "primevue/card";
import Column from "primevue/column";
import DataTable from "primevue/datatable";

import ProductTrendService from "@/services/product_trend_service";

import store from "@/store";
import { formatUSDate } from "@/utils/formatter";

export default defineComponent({
  components: {
    BlockUI,
    Button,
    Card,
    Column,
    DataTable,
  },

  setup() {
    onMounted(() => {
      listSavedQuery();
    });

    const confirm = useConfirm();
    const toast = useToast();
    const router = useRouter();

    const blocked = ref(true);
    const dataSource = ref([]);

    const listSavedQuery = () => {
      blocked.value = true;
      ProductTrendService.listSavedQuery()
        .then(({ data }) => {
          dataSource.value = data.saved_queries;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          blocked.value = false;
        });
    };

    const runSavedQuery = (query) => {
      confirm.require({
        message: `Are you sure you want to run saved query?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Run",
        rejectLabel: "Cancel",
        accept: () => {
          store.dispatch("setSavedQuery", query).then(() => {
            router.push({ name: "ProductTrendResearch" });
          });
        },
      });
    };

    const deleteSavedQuery = (id) => {
      confirm.require({
        message: `Are you sure you want to delete?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Delete",
        rejectLabel: "Cancel",
        accept: () => {
          blocked.value = true;

          ProductTrendService.deleteSavedQuery(id)
            .then(() => {
              toast.add({
                severity: "success",
                summary: "Product Trend",
                detail: "Deleted Saved query",
                life: 3000,
              });
            })
            .catch((e) => {
              console.error(e);
              toast.add({
                severity: "error",
                summary: "Product Trend",
                detail: "Delete Saved query fail",
                life: 3000,
              });
            })
            .finally(() => {
              blocked.value = false;
              listSavedQuery();
            });
        },
      });
    };

    return {
      blocked,
      dataSource,

      formatUSDate,

      runSavedQuery,
      deleteSavedQuery,
    };
  },
});
</script>

<style lang="scss" scoped>
.main-content {
  margin-top: 1rem;
}
</style>
